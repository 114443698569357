import "antd/dist/reset.css";
import "./highcharts.config";
import "./parse.config";

import { init, StorageAdapterLS } from "@opendash/core";
import { getCurrentLanguageSync } from "@opendash/i18n";
import { registerIconPack } from "@opendash/icons";
import { HighchartsPlugin } from "@opendash/plugin-highcharts";
import { MonitoringPlugin, $monitoring  } from "@opendash/plugin-monitoring";
import { OpenwarePlugin } from "@opendash/plugin-openware";
import { $parse, ParsePlugin } from "@opendash/plugin-parse";
import { ParseMonitoringPlugin } from "@opendash/plugin-parse-monitoring";
import { TimeseriesPlugin } from "@opendash/plugin-timeseries";
import CWWidget  from "./widgets/cw-table";
import CWCWidget  from "./widgets/cw-compare";
import dayjs from "dayjs";

dayjs.locale(getCurrentLanguageSync());

init("opendash", async (factory) => {
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Logo:
  factory.ui.setLogoImage("/cw.png");

  // Translations:
  factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch", "en", true);

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(new TimeseriesPlugin());
  await factory.use(new MonitoringPlugin());

  await factory.use(
    new ParsePlugin({
      authLdapActive: false,
      useLiveQueries: false,
      disableNavigationWidgetPreset:true,
    })
  );
  await factory.use(
    new ParseMonitoringPlugin({
      liveQueries: false,
    })
  );

  await factory.use(
    new OpenwarePlugin({
      secure: true,
      host: "crowdwater.fit.fraunhofer.de",
      filterValuesOlderThanMS: 0,
      disableFeature: {
        menu: {
          DataMeta: true,
          DataHierachies: true,
        },
        slideshow: true,
        dataCollection: false,
        VKPI: true,
        reporting: true,
        reportingFeatures: {
          adhoc:false,
          schedule:false,
          img:true,
          export:false
        }
      },
    })
  );

  await factory.use(new HighchartsPlugin());
  await $monitoring.registerWidget(CWWidget);
  await $monitoring.registerWidget(CWCWidget);

  //Translation
  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async () => await import("./translations/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    async () => await import("./translations/en.json")
  );
 
  factory.registerStaticNavigationGroup({
    label: "openware:reporting.label",
    order: 30,
    id: "admin/reporting",
  });

//Parse Tenant
factory.registerStaticNavigationItem({
  id: "admin/parse/tenant",
  group: "admin/parse",
  place: "sidebar",
  order: 1,
  label: "parse-admin:classes.OD3_Tenant.label",
  icon: "fa:building",
  link: "/admin/parse/OD3_Tenant",
  routeCondition: "/admin/**",
  activeCondition: "/admin/parse/OD3_Tenant",
  permission: "parse-admin",
});

//Class Config


  $parse.ui.setClassConfig({
    className: "OD3_WidgetPreset",
    titleFields: ["label"],
    displayFields: ["label", "tags", "description"],
    createFields: [],
    editFields: ["label", "tags", "description"],
  });

  $parse.ui.setClassConfig({
    className: "_User",
    titleFields: ["name"],
    displayFields: ["name", "tenant", "username", "email" ,"tenantAdmin", "updatedAt"],
    createFields: ["name", "username", "email", "tenant" ,"password","tenantAdmin"],
    editFields: ["name", "username", "email", "tenant" ,"password","tenantAdmin"],
  });

  $parse.ui.setDefaultView("OD3_WidgetPreset", {
    type: "table",
  });
  $parse.ui.setClassConfig({
    className: "OD3_Source",
    titleFields: ["name"],
    displayFields: ["name", "machineNumber", "tag", "type", "price", "parent"],
    createFields: ["name", "machineNumber", "tag", "type", "price", "parent"],
    editFields: ["name", "machineNumber", "type", "price", "parent"],
  });
  $parse.ui.setClassConfig({
    className: "OD3_Tenant",
    titleFields: ["label"],
    displayFields: ["label"],
    createFields: ["label"],
    editFields: ["label"],
  });

  $parse.ui.setClassConfig({
    className: "OD3_Permission",
    titleFields: ["label"],
    displayFields: ["label", "key"],
    createFields: ["label", "key"],
    editFields: ["label", "key"],
  });

  $parse.ui.setClassConfig({
    className: "OD3_Dashboard",
    titleFields: ["name"],
    displayFields: ["name", "source"],
    createFields: ["name"],
    editFields: ["name"],
  });

  $parse.ui.setDefaultView("OD3_Source", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "_Role",
    titleFields: ["label"],
    displayFields: ["label", "name", "users", "createdAt", "updatedAt"],
    createFields: ["label", "name"],
    editFields: ["label", "name", "users"],
    defaultACL: () => {
      if (!$parse.user.id()) {
        return {};
      }

      return {
        [$parse.user.id()]: {
          read: true,
          write: true,
        },
      };
    },
  });
  $parse.ui.setDefaultView("_Role", {
    type: "table",
  });

  
}).then((app) => {
  console.log("init open.DASH");
});
